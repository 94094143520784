@import "/src/assets/styles/variables";

.date {
    display: flex;
    align-items: center;
    gap: 8px;

    &__duration {
        display: flex;
        height: 22px;
        padding: 5px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: $black-200;
        color: $black-700;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        opacity: 0.6
    }
}
