.note {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__btn {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__text {
        overflow: hidden;
        color: #1E1E1E;
        font-size: 11px;
        font-weight: 500;
    }

    &__text_empty {
        overflow: hidden;
        color: #1E1E1E;
        font-size: 11px;
        font-weight: 500;
        opacity: 0.2;
    }

}
