@import '/src/assets/styles/variables';

.filter {
    padding: 9px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid transparent;

    &__filter{
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;

    }

    &__active {
        border-radius: 16px;
        border: 1px solid $black-300;
        background: #EBFF00;
    }

    &__amount {
        padding: 5px 6px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.60);
        overflow: hidden;
        color: $black-700;
        font-size: 11px;
        font-weight: 500;
        line-height: 120%;
    }

}
