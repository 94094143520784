.load {
    &__btn {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
        display: flex;
        height: 42px;
        padding: 9px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    &__btn:hover {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #EBFF00;
    }
}
