@import '/src/assets/styles/variables';

.select {
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-left: 8px;
    position: relative;

    &__list {
        position: absolute;
        z-index: 2;
        top: 30px;
        left: 10px;
        display: flex;
        width: 240px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #FFF;
        box-shadow: 0px 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
    }

    &__item_active {
        color: $black-400;
    }

    &__item:hover{
        color: $black-400;
    }
}
