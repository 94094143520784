@import '/src/assets/styles/variables';

.menu {
    position: relative;

    &__list {
        z-index: 9999;
        position: absolute;
        right: 0;
        top: 20px;
        width: 192px;
        padding: 14px;
        border-radius: 16px;
        background: var(--White, #FFF);
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
        display: flex;
        flex-direction: column;
        gap: 19px;
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 6px;
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
    }

    &__item:hover {
        color: $black-500;
    }

}
