@import  '../../assets/styles/variables';

.card {
  width: 880px;
  padding: 40px;
  background: $primary-bg-color;
  border-radius: 20px;
  box-shadow: 0px 60px 60px -32px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  gap: 48px;

  &__left {
    width: calc(50% - 24px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    width: calc(50% - 24px);
  }


}
