@import '../../assets/styles/variables';

.layout {
  background: url("../../assets/images/background.png") center/cover no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__title {
    color: $primary-text-color;
    font-size: 22px;
    margin-bottom: 60px;
    text-align: center;
  }


}
