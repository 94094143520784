@import '/src/assets/styles/variables';

.tag {
    display: flex;
    height: 22px;
    padding: 2px 4px 2px 8px;
    align-items: center;
    gap: 2px;
    border-radius: 6px;
    background: $black-500;
    color: #FFF;
    text-align: center;
    font-size: 11px;
    font-weight: 500;

    &__delete {
        margin-bottom: -3px;
    }
}
