@import '/src/assets/styles/variables';

.modal {
    display: flex;
    width: 300px;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: var(--white, #FFF);
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black-700;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 24px;
    }

    &__body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding-bottom: 18px;
        margin-bottom: 18px;
        border-bottom: 1px solid #CCC;
    }

    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
    }

    &__clear {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__input {
        text-align: center;
        height: 38px;
        width: 105px;
        padding: 7px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #FFF;
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
    }

    &__input::placeholder {
        color: #959595;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
    }


}
