.modal {
    &__overlay {
        display: flex;
        justify-content: center;
        padding-top: 10vh;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(30, 30, 30, 0.30);
        z-index: 99999;
    }
}
