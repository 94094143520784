@import '/src/assets/styles/variables';

.upload {

    &__input {
        width: 0;
        opacity: 0;
    }

    &__btn {
        height: 154px;
        padding: 12px 0;
        border-radius: 16px;
        border: 1px solid #C7C7C7;
        background: #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
    }

    &__container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;
    }

    &__text {
        width: 150px;
        text-align: left;
    }

    &__title {
        color: $black-700;
        font-size: 14px;
        font-weight: 500;
        line-height: 110%;
    }

    &__format {
        color: $black-500;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }
}
