.call {
    height: 100vh;
    padding: 10px 12px;
    overflow-y: auto;
    background: linear-gradient(0deg, #EBEBEB 0%, #EBEBEB 100%), #C0C0C0;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__language {
        width: 95%;
        display: flex;
        justify-content: flex-end;
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__wave {
        //height: 60px;
        border-radius: 16px;
        border: 1px solid rgba(5, 5, 5, 0.20);
        padding: 12px;
        background: rgba(0, 0, 0, 0.05);
        margin-bottom: 12px;
    }

    &__list {
        width: 80%;
        margin: 0 auto;
        max-height: calc(100vh - 230px);
        overflow-y: auto;
        padding-bottom: 200px;
    }

    &__card {
        display: flex;
        width: 420px;
        max-width: 420px;
        min-height: 84px;
        padding: 8px 12px 16px 12px;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }

    &__main {
        display: flex;
        justify-content: space-between;
    }


}
