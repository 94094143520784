@import '/src/assets/styles/variables';

.modal {
    display: flex;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 400px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #FFF;
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    &__subtitle {
        color: $black-700;
        font-size: 15px;
        font-weight: 500;
    }

    &__textarea {
        width: 100%;
        height: 250px;
        padding: 8px 16px;
        border-radius: 16px;
        border: 1px solid #C7C7C7;
        background: var(--White, #FFF);
    }

    &__textarea:disabled {
        border-radius: 16px;
        border: 1px solid #C7C7C7;
        background: #FFF;
        color: #959595
    }


    &__left {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 50%;
    }

    &__right {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 50%;
    }

    &__btn {
        width: 150px;
        display: flex;
        align-items: center;
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

}
