@import '/src/assets/styles/variables';


.additional {

    &__container {
        position: relative;
    }

    &__btn {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 7.273px;
        justify-content: center;
        align-items: center;
        border-radius: 8.727px;
        border: 0.727px solid rgba(0, 0, 0, 0.20);
        background: #F2F2F2;
    }

    &__btn:hover {
        background: var(--Brand-Primary, #EBFF00);
    }

    &__list {
        right: 0;
        z-index: 2;
        position: absolute;
        display: flex;
        width: 210px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 16px;
        background: var(--White, #FFF);
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 8px;
    }



}
