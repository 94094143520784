@import '/src/assets/styles/variables';

.filter {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 40px;

    &__head {
        display: flex;
        padding: 7px 10px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 3px solid transparent;
    }

    &__tooltip {
        position: absolute;
        top: 20px;
        width: 200px;
        z-index: 3;
        right: 120px;
        padding: 14px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid $black-200;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    }

    &__head_active {
        background: rgba(198, 203, 211, 0.40);
        border: 3px solid #D5D7DB;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 4px;
        overflow: hidden;
        color: $black-700;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
    }

    &__list {
        top: 50px;
        z-index: 2;
        position: absolute;
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 6px;
        width: 100%;
    }
    &__item_active {
        color: $black-400;
    }

    &__item:hover {
        color: $black-400;
    }

    &__icon {
        min-width: 20px;
    }


}
