.status {

    &__container {
        position: relative;
        display: flex;
    }

    &__btn {
        display: flex;
        padding: 5px 8px;
        justify-content: center;
        align-items: center;
        color: rgba(30, 30, 30, 0.6);
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        border-radius: 6px;
    }

    &__list {
        z-index: 5;
        position: absolute;
        left: 20px;
        top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 220px;
        padding: 14px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: var(--White, #FFF);
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item:hover {
        color: #959595;
    }

    &__item_active {
        color: #959595;
    }

    &__circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }

    &__name {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__empty {
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        border-radius: 6px;
        padding: 5px 8px;
        background: #FCC;
    }


}
