.empty {
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
