@import 'src/assets/styles/variables';

.amount {
    display: flex;
    align-items: center;
    gap: 4px;

    &__title {
        color: $black-700;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__value {
        display: flex;
        height: 22px;
        min-width: 22px;
        padding: 5px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: $black-500;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 13.2px */
        border-radius: 6px;
        background: $black-200;
    }

}
