@import '/src/assets/styles/variables';

.layout {
    height: 100vh;
    display: flex;
    background: url("../../assets/images/main-bg.png") center/cover no-repeat;

    &__container {
        height: 100%;
        min-width: calc(100vw - 220px);
        max-width: calc(100vw - 60px);
        width: 100% ;
        padding: 8px 24px;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__title {
        color: $black-700;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__info {
        display: flex;
        align-items: center;
    }

}
