@import '/src/assets/styles/variables';

.select {
    position: relative;

    &__btn {
        padding: 5px 6px;
        border-radius: 6px;
        text-transform: capitalize;
        color: $black-700;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 13.2px */
    }

    &__list {
        z-index: 2;
        left: 10px;
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 19px;
        position: absolute;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
        color: $black-700;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__item:hover {
        color:$black-400;
    }

    &__item_active {
        color:$black-400;
    }

    &__emotion {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }

}
