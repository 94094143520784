@import '/src/assets/styles/variables';

.clear {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $black-700;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
