@import '/src/assets/styles/variables';

.table {
    width: 100%;
    position: relative;

    &__thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    &__container {
        width: 100%;
        height: calc(100vh - 300px);
        overflow-y: auto;
    }

    &__cell_head {
        height: 48px;
        padding: 5px 12px;
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        background: #DFDFDF;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__cell_first {
        height: 48px;
        padding: 5px 12px;
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        border-left: 1px solid rgba(0, 0, 0, 0.10);
        background: #DFDFDF;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__cell_last {
        height: 48px;
        padding: 5px 12px;
        border-bottom-right-radius: 16px;
        border-top-right-radius: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.10);
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        border-right: 1px solid rgba(0, 0, 0, 0.10);
        background: #DFDFDF;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__tbody {
        color: $black-700;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__tbody tr td {
        background-color: #fff;
        padding: 15px 12px;
        border-bottom: 1px solid #EBEBEB;
    }

    &__tbody tr:last-child td{
        border-bottom: 1px solid transparent;
    }

    &__tbody tr:first-child > td:first-child {
        border-top-left-radius: 16px;
        background: #FFF;
    }

    &__tbody tr:first-child > td:last-child {
        border-top-right-radius: 16px;
        background: #FFF;
    }

    &__tbody tr:last-child > td:last-child {
        border-bottom-right-radius: 16px;
        background: #FFF;
    }

    &__tbody tr:last-child > td:first-child {
        border-bottom-left-radius: 16px;
        background: #FFF;
    }

    &__note {
        width: 550px;
        margin-right: 20px;
    }

    &__tr_inactive {
        opacity: 0.8;
    }

    &__tr_inactive td {
        background-color: #DFDFDF!important;
    }

    &__item_inactive {
        border-radius: 6px;
        background: #FFF;
        height: 22px;
        padding: 5px 6px;
        gap: 10px;
    }
}
