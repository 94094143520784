@import '/src/assets/styles/variables';


.menu {
    position: relative;
    &__btn {
        display: inline-flex;
        height: 42px;
        padding: 9px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
    }

    &__btn:hover {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #EBFF00;
    }

    &__list {
        z-index: 9999;
        right: 0;
        top: calc(100% + 5px);
        position: absolute;
        display: flex;
        width: 210px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--Black, #1E1E1E);
        font-size: 13px;
        font-weight: 500;
    }

    &__item_name {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__text {
        padding-top: 3px;
    }

    &__duration {
        z-index: 9999;
        right: 0;
        top: calc(100% + 60px);
        position: absolute;
        display: flex;
        width: 210px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }


}
