.recover {

    &__container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 14px;
    }

    &__links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__success {
        color: #959595;
        font-size: 16px;
        font-weight: 500;
        line-height: 160%;
    }

}
