.tooltip {
    position: relative;
    overflow: hidden;

    &__tooltip {
        position: absolute;
        top: 20px;
        width: 400px;
        z-index: 3;
        right: 80px;
        padding: 14px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid #EBEBEB;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

}
