@import 'src/assets/styles/variables';

.modal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 680px;
    padding: 24px 16px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    &__header {
        margin-bottom: 24px;
    }

    &__title {
        color: $black-700;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__description {
        color: #959595;
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        max-height: 35vh;
        overflow-y: auto;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
    }

    &__btns {
        width: 350px;
        display: flex;
        align-items: center;
    }

    &__error {
        color: $primary-red;
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;
    }
}
