@import '/src/assets/styles/variables';

.dropdown{
    position: relative;

    &__btn {
        display: flex;
        align-items: center;
        gap: 6px;
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__list {
        position: absolute;
        bottom: 30px;
        left: -100px;
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #FFF;
        box-shadow: 0px 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: $black-700;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__item_active {
        color: $black-400;
    }

    &__item:hover {
        color: $black-400;
    }


}
