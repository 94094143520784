@import '../../../../assets/styles/variables';

.lang {
    position: relative;
    width: 100px;

    &__select {
        display: flex;
        height: 42px;
        padding: 9px;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
    }

    &__list {
        z-index: 3;
        position: absolute;
        top: 35px;
        right: 80px;
        width: 140px;
        padding: 14px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid $black-200;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item_active {
        color: $black-400;
    }

    &__value {
        min-width: 50px;
        font-size: 12px;
        background: $primary-yellow;
        padding: 0 4px;
        border-radius: 4px;
    }


}
