@import 'src/assets/styles/variables';

.info {
    position: relative;

    &__btn {
        height: 44px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
    }

    &__list {
        z-index: 4;
        position: absolute;
        right: 5px;
        top: 50px;
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #fff;
        box-shadow: 0px 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__subtitle {
        color: $black-400;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__text {
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

}
