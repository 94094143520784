@import '/src/assets/styles/variables';

.profile {
    height: 100vh;
    padding: 10px 24px;
    background: linear-gradient(0deg, #EBEBEB 0%, #EBEBEB 100%), #C0C0C0;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 19px;
    }

    &__card {
        display: flex;
        justify-content: space-between;
        padding: 42px;
        border-radius: 16px;
        border: 1px solid $black-200;
        background: $primary-bg-color;
    }

    &__avatar {
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 56px;
        background-color: #855AFF;
        font-size: 64px;
        color: #FFF;
        font-weight: 500;
    }

    &__left {
        min-width: 70%;
        display: flex;
        flex-direction: column;
        gap: 68px;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    &__input {
        width: calc(50% - 12px);
    }

    &__grid {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    &__line {
        display: flex;
        gap: 24px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 48px;
    }

    &__apply {
        width: 180px;
    }

}
