$black-100: #efefef;
$black-200: #EBEBEB;
$black-300: #C7C7C7;
$black-400: #959595;
$black-500: #565656;
$black-600: #393939;
$black-700: #1E1E1E;

$primary-text-color: #ffc436;
$secondary-text-color: #07264f;
$primary-bg-color: #fff;
$dark-bg-color: #001734;

$primary-red: #FC6333;
$primary-yellow: #EBFF00;
