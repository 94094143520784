@import '/src/assets/styles/variables';

.info {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 6px;

    &__status {
        width: auto;
    }

    &__wrapper {
        height: 42px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 9px;
        border-radius: 16px;
        background-color: #fff;
    }

    &__duration {
        padding: 8px;
        overflow: hidden;
        color: $black-500;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        border-radius: 6px;
        background: #EBEBEB;

    }

    &__problem {
        width: 42px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        border-radius: 16px;
    }

    &__mark {
        overflow: hidden;
        color: $black-700;
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: 500;
    }

}
