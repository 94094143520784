@import '/src/assets/styles/variables';

.peeked {
    padding: 5px 6px;
    border-radius: 6px;
    background: $black-200;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}
