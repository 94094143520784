@import '/src/assets/styles/variables';

.switch {
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    color: $black-700;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    background: $primary-bg-color;
    border-radius: 8px;

    &__item {
        padding: 8px 16px;
        border-radius: 8px;
        overflow: hidden;
    }

    &__item_active {
        padding: 8px 16px;
        background: $black-500;
        color: $primary-bg-color;
        border-radius: 8px;
    }
}
