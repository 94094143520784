@import '/src/assets/styles/variables';

.card {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    gap: 36px;
    padding-left: 12px;
    border: 1px solid transparent;

    &__active {
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.05);
    }

    &__card_selected {
        border-radius: 12px;
        border: 1px solid #E15B53;
        background: rgba(225, 91, 83, 0.05);
    }

    &__role {
        width: 100px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
    }

    &__role_active {
        color: $black-700;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
    }

    &__emotion {
        padding: 6px;
        border-radius: 6px;
        background: #FFF;
        overflow: hidden;
        color: #000;
        text-overflow: ellipsis;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__text {
        width: 100%;
        color: $black-700;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        text-align: left;
    }

    &__list {
        position: relative;
    }

    &__emotions {
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__emotions_item {
        display: flex;
        align-items: center;
    }

    &__locale {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
        border-radius: 16px;
        padding: 8px 12px 8px 12px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

}
