@import '/src/assets/styles/variables';

.company {
    padding: 4px;
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;

    &__btn {
        width: 32px;
        height: 32px;
        border-radius: 12px;
        background-color: #855AFF;
        color: #fff;
    }

    &__item {
        color: $black-700;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    &__item_active {
        color: $black-400;
    }

    &__item:hover {
        color: $black-400;
    }

    &__fullname {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__list {
        position: absolute;
        z-index: 5;
        left: -180px;
        top: 42px;
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 7.2px;
        background-color: #855AFF;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
