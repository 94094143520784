.recover {

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

    &__links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}
