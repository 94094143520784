@import '/src/assets/styles/variables';

.status {
    padding: 8px 8px;
    border-radius: 6px;
    color: $black-700;
    text-overflow: ellipsis;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;

    &__active {
        opacity: 0.6;
    }

    &__container {
        width: 220px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__list {
        z-index: 3;
        position: absolute;
        top: 35px;
        right: 80px;
        width: 240px;
        padding: 14px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 19px;
        border-radius: 16px;
        border: 1px solid $black-200;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }

    &__label {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item_active {
        color: $black-400;
    }

    &__item:hover {
        color: $black-400;
    }

    &__employee {
        color: #1E1E1E;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }
}

.status:hover{
    opacity: 0.6;
}
