@import "/src/assets/styles/variables";

.select {

    &__label {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        height: 24px;
    }

    &__item {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item:hover > &__label {
        color: $black-400;
    }

    &__item_active {
        color: $black-400;
    }

    &__circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }


}
