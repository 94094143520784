@import 'normalize';
@import '../../assets/styles/fonts/font.scss';

*{
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Fixel Text', sans-serif;
}

#root {
  position: relative;
  overflow-y: hidden;
  min-height: 100vh;
}

div {
  box-sizing: border-box;
}

p {
  margin: 0;
  font-family: inherit;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
}

input {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  box-sizing: border-box;
  font-family: inherit;
}

textarea {
  box-sizing: border-box;
  font-family: inherit;
}

input[type="checkbox"]{
  cursor: pointer;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  margin: 0;
}

textarea {
  resize: none;
  border: 0;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  font-family: $fontPrimary;
}

a:hover{
  text-decoration: none;
}

label {
  margin: 0;
  font-family: inherit;
}

img {
  display: block;
}
