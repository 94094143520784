@import '/src/assets/styles/variables';

.info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: 360px;
    padding: 36px;
    border-radius: 24px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #EBFF00;
    width: 100%;


    &__title {
        overflow: hidden;
        color: $black-700;
        font-size: 20px;
        font-weight: 500;
        line-height: 120%;
    }

    &__amount {
        overflow: hidden;
        color: $black-700;
        font-size: 96px;
        font-weight: 500;
        line-height: 120%;
    }

    &__description {
        overflow: hidden;
        color: $black-400;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__limit {
        overflow: hidden;
        color: $black-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
    }
}


.small {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: 110px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #EBFF00;

    &__title {
        overflow: hidden;
        color: $black-700;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
    }

    &__amount {
        overflow: hidden;
        color: $black-700;
        font-size: 32px;
        font-weight: 500;
    }

    &__description {
        overflow: hidden;
        color: $black-400;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
    }
}
