@import '/src/assets/styles/variables';

.player {
    display: flex;
    margin-bottom: 12px;
    justify-content: center;

    &__main {
        display: flex;
        padding: 4px 14px 4px 4px;
        align-items: center;
        gap: 20px;
        border-radius: 16px;
        background: #FFF;
    }

    &__play {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    &__play_btn {
        display: flex;
        width: 34px;
        height: 34px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        background-color: $black-700;
        border-radius: 12px;
    }

    &__play_time {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__current {
        width: 30px;
        color: $black-700;
        text-overflow: ellipsis;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__full {
        width: 30px;
        color: #909090;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__increase {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__x_active {
        overflow: hidden;
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;
    }

    &__x {
        overflow: hidden;
        color: $black-200;
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;
    }

    &__volume {
        display: flex;
        padding: 4px 12px;
        align-items: center;
        gap: 12px;
        border-radius: 16px;
        background: #FFF;
    }

    &__volume_value {
        width: 30px;
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__volume_btn {
        height: 24px;
    }

}
