@import '/src/assets/styles/variables';

.load {

    &__input {
        width: 0;
        opacity: 0;
    }

    &__btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 163px;
        padding: 12px;
        gap: 10px;
        border-radius: 24px;
        background: $black-700;
        width: 100%;
    }

    &__title{
        color: #EBFF00;
        font-size: 20px;
        font-weight: 500;
        line-height: 120%;
    }

    &__description {
        color: #FFF;
        font-size: 12px;
        font-weight: 400;
        line-height: 110%;
    }

    &__format {
        color: $black-400;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
    }

}

.additional {
    &__input {
        width: 0;
        opacity: 0;
    }

    &__title {
        color: $black-700;
        font-size: 15px;
        font-weight: 500;
        line-height: 120%;
    }

    &__btn {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 110px;
        padding: 12px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: linear-gradient(0deg, #DFDFDF 0%, #DFDFDF 100%), var(--Brand-Primary, #EBFF00);
        cursor: pointer;
    }

    &__btn_disabled {
        opacity: 0.6;
        cursor: auto;
    }

    &__empty {
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    &__additional {
        color: $black-700;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
    }
}
