@import '/src/assets/styles/variables';

.modal {
    display: flex;
    width: 360px;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: var(--white, #FFF);
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__search {
        width: 100%;
        background: transparent;
    }

    &__title {
        color: $black-700;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__amount {
        display: flex;
        height: 22px;
        min-width: 22px;
        padding: 5px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: $black-400;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        border-radius: 6px;
        background: $black-200;
    }

    &__clear {
        display: flex;
        align-items: center;
        gap: 6px;
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
    }

    &__list {
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 12px;
    }

    &__footer {
        border-top: 1px solid $black-400;
        padding-top: 12px;
        width: 100%;
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
    }


}
