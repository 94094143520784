@import '/src/assets/styles/variables';


.phone {
    display: flex;
    align-items: center;
    gap: 8px;

    &__btn {
        display: flex;
        height: 22px;
        padding: 5px 6px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: $black-200;
        overflow: hidden;
        color: $black-700;
        text-overflow: ellipsis;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }
}
