@import '/src/assets/styles/variables';

.pagination {
    width: 380px;
    display: flex;
    align-items: center;
    gap: 6px;


    &__page {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border: 1px solid transparent;
        color: $black-700;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        font-style: normal;
    }

    &__page:hover {
        border-radius: 12px;
        background: rgba(30, 30, 30, 0.10);
    }

    &__next {
        margin-left: 36px;
    }

    &__prev {
        margin-right: 36px;
    }

    &__page_active {
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #EBFF00;
    }


}
