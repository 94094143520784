@import '/src/assets/styles/variables';

.analyze {

    &__btn {
        display: flex;
        height: 42px;
        padding: 9px;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
    }

    &__btn:hover {
        color: $black-500;
    }

    &__modal {
        display: flex;
        width: 400px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: var(--white, #FFF);
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        text-align: center;

    }


}
