@import 'src/assets/styles/variables';

.modal {
    display: flex;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 400px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #FFF;
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);


    &__title {
        color: $black-700;
        font-size: 15px;
        font-weight: 500;
    }

    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

    }
}
