.automatic {

    &__dates {
        display: flex;
        align-items: center;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 12px;
        gap: 8px;
        font-size: 12px;
        font-weight: 500;
    }

    &__footer {
        display: flex;
        align-items:center;
        justify-content: space-between;
        padding: 12px 0
    }

    &__total {
        background-color: #fff;
        padding: 4px 6px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 500;
        margin-left: 10px;
    }

    &__header_left {
        display: flex;
        align-items: center;
        gap: 8px;
    }


}
