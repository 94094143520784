@import '/src/assets/styles/variables';

.input {
    width: 100%;
    position: relative;

    &__input {
        display: flex;
        height: 58px;
        padding: 8px 16px;
        align-items: center;
        border-radius: 16px;
        border: 1px solid $black-300;
        background: $primary-bg-color;
        outline: none;
        width: 100%;
        color: $black-700;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__input::placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: $black-400;
    }

    &__input_active {
        padding-top: 28px;
    }

    &__title {
        color: $black-400;
        text-overflow: ellipsis;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        position: absolute;
        top: 8px;
        left: 16px;
    }

    &__password {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-40%);
    }

    &__error {
        border: 1px solid $primary-red;
    }

    &__error_text {
        position: absolute;
        bottom: -20px;
        left: 16px;
        font-size: 13px;
        color: $primary-red;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__disabled_icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-40%);
    }

    &__disabled {
        background-color: $black-100;
    }


}
