@import '/src/assets/styles/variables';

.info {
    position: relative;
    width: 130px;
    display: flex;
    justify-content: flex-end;

    &__info {
        cursor: pointer;
        width: 122px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 42px;
        padding: 4px 8px 4px 4px;
        border-radius: 16px;
        background: $primary-bg-color;
    }

    &__avatar {
        width: 32px;
        height: 32px;
        background-color: #855AFF;
        border-radius: 12px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    &__name {
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
        line-height: 120%;
    }

    &__text {
        color: $black-700;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__list {
        position: absolute;
        top: 50px;
        left: -25px;
        width: 160px;
        padding: 16px;
        border-radius: 20px;
        background-color: $primary-bg-color;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: var(--white, #FFF);
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
        z-index: 5;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-top: 16px;
    }


    &__item_profile {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 16px;
        border-bottom: 1px solid $black-300;
    }

}
