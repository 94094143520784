.search {
    position: relative;

    &__input {
        display: flex;
        width: 240px;
        height: 42px;
        padding: 9px 9px 9px 40px;
        align-items: center;
        gap: 8px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
        outline: none;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-41%);
        left: 9px;
    }
}
