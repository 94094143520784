@import '/src/assets/styles/variables';

.file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 208px;
    border-radius: 16px;
    padding: 12px 6px;
    border: 1px solid $black-300;
    background: linear-gradient(90deg, #EBFF00 0%, rgba(255, 255, 255, 0.00) 100%), #EBEBEB;

    &__name {
        color: $black-700;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__delete {

    }
}
