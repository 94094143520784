.modal {
    display: flex;
    width: 400px;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: var(--white, #FFF);
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    &__title {
        font-size: 16px;
        margin-bottom: 20px;
    }


    &__footer {
        width: 100% ;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
