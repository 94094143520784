.info {
    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 12px;
    }

    &__card {
        width: calc(100% / 3 - 4px);
    }
}
