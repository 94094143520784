@import '/src/assets/styles/variables';

.button {

    &__button {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 20px 16px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 120%;
        border-radius: 16px;
        background: $black-700;
    }

    &__button:hover {
        background-color: $black-600;
    }

    &__button:disabled {
        background-color: $black-500;
    }
}
