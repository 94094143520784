.limits {
    display: flex;
    align-content: center;
    font-size: 11px;


    &__limit {
        padding: 5px 6px;
        border-radius: 6px;
        background: #CCFFD1;
    }

    &__limit_time {
        padding: 5px 6px;
        border-radius: 6px;
        background: #FFE8BC;
    }

    &__full {
        background: #FCC;
    }

}
