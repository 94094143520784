.grid {
    width: 100%;

    &__filters {
        display: flex;
        align-items: center;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__header_left {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__search {
        width: 200px;
    }


    &__footer {
        display: flex;
        align-items:center;
        justify-content: space-between;
        padding: 12px 0
    }

}
