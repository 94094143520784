@import '../../assets/styles/variables';

.sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 240px;

    &__closed {
        width: 60px;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }

    &__btn {
        height: 60px;
        border-radius: 16px;
        border: 1px solid #FFF;
        background: rgba(255, 255, 255, 0.40);
        backdrop-filter: blur(10px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__list {
        height: calc(100vh - 60px);
        border: 1px solid  #fff;
        padding: 8px;
        background: rgba(255, 255, 255, 0.40);
        backdrop-filter: blur(10px);
        border-radius: 16px;
    }

  &__menu {
        width: 100%;
      display: flex;
      flex-direction: column;
      gap: 28px;
  }

    &__item {
        padding: 12px;
    }

    &__item_active {
        border-radius: 12px;
        background: #FFF;
    }

  &__item:hover {
      border-radius: 12px;
      background: #FFF;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $black-700;
    font-size: 13px;
    font-weight: 500;
    line-height: 120%;
  }

  &__link:focus {
    color:rgb(255, 240, 206);
  }

}
