@import  'src/assets/styles/variables';


.menu {
    position: relative;

    &__btn {
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        height: 42px;
        padding: 9px 2px 9px 12px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: rgba(0, 0, 0, 0.05);
        color: $black-700;
        font-size: 13px;
        font-weight: 500;
    }


    &__list {
        position: absolute;
        right: 0;
        width: 220px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        z-index: 9999;
    }

    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black-700;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
    }

    &__item_name {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__statuses {
        left: 100%;
        position: absolute;
        z-index: 9999;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        background: var(--White, #FFF);
        box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);
        display: flex;
        width: 220px;
        padding: 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    &__amount {
        display: flex;
        padding: 5px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: var(--White, #FFF);
    }





}

.modal {
    display: flex;
    width: 400px;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: var(--white, #FFF);
    box-shadow: 0 40px 60px -30px rgba(0, 0, 0, 0.40);

    &__title {
        font-size: 16px;
        margin-bottom: 20px;
    }


    &__footer {
        width: 100% ;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

