@import '/src/assets/styles/variables';

.button {
    &__button {
        width: 100%;
        color: $black-400;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        padding: 20px 16px;
    }

    &__button:hover {
        opacity: 0.8;
    }


}
