@import '/src/assets/styles/variables';

.calendar {

    &__empty {
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #1E1E1E;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    &__active {
        display: flex;
        align-items: center;
        border-radius: 16px;
        padding: 8px;
        gap: 4px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #EBFF00;
    }

    &__clear {
        margin-bottom: -5px;
    }

    &__date {}

    &__amount {
        padding: 5px 6px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.60);
        overflow: hidden;
        color: $black-700;
        font-size: 11px;
        font-weight: 500;
        line-height: 120%;
    }
};
