.mark {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__value {
        padding: 5px 12px;
        border-radius: 6px;
    }
}
